<template>
  <div id="loader-wrapper" :style="style"
       :class="wrapper_class">
    <div id='loader' :class="loader_class"></div>
  </div>
</template>

<script setup lang="ts">
import {computed} from "vue";

const props = defineProps({
  height: String,
  wrapper_class: String,
  loader_class: String
})

const style = computed(() => {
  let style = "";
  if (props.height) {
    style += "height: " + props.height;
  }
  return style;
})
</script>

